import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterDetails: [],
  concatenatedFilters: '',
  removedFilters: [], 
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    addFilter: (state, action) => {
      const filterPayload = action.payload;
      state.filterDetails = [...state.filterDetails, ...filterPayload.filterDetails];
      const filterString = filterPayload.filterDetails
        .map((filter) => `${filter.filterValues.menuEntity}_${filter.filterValues.key}=${filter.filterValues.value}`)
        .join('&');
      state.concatenatedFilters = state.concatenatedFilters
        ? `${state.concatenatedFilters}&${filterString}`
        : filterString;
    },
    removeFilter: (state, action) => {
      const filtersToRemove = action.payload;
      state.removedFilters = filtersToRemove;

      state.filterDetails = state.filterDetails.filter(
        (filter) => !filtersToRemove.includes(filter.id)
      );

      state.concatenatedFilters = state.filterDetails
        .map((filter) => `${filter.filterValues.menuEntity}_${filter.filterValues.key}=${filter.filterValues.value}`)
        .join('&');
    },
    applyFilterChanges: (state) => {
      state.removedFilters = [];
    },
    resetFilters: (state) => {
      state.filterDetails = [];
      state.concatenatedFilters = '';
      state.removedFilters = [];
    },
    removePerFilter: (state, action) => {
      const index = action.payload;
      state.filterDetails.splice(index, 1);
      state.concatenatedFilters = state.filterDetails
        .map((filter) => {
          const newStr = `${filter?.filterValues?.menuEntity}_${filter?.filterValues?.key}=${filter?.filterValues?.value}`;
          return newStr;
        })
        .join('&');
    },
  },
});

export const { addFilter, resetFilters, removeFilter, applyFilterChanges,removePerFilter } = filterSlice.actions;

export default filterSlice.reducer;
