// features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { setSession, isValidToken } from '../../utils/jwt';
import { ApiEndpoints } from '../../config';

const initialState = {
  isAuthenticated: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserDetailsLogin(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    logoutUser(state) {
        state.isAuthenticated = false;
        state.user = null;
      },
  },
});

export const { setUserDetailsLogin,logoutUser } = authSlice.actions;

export default authSlice.reducer;
